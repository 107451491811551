/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

/*
Add icons
  */
$fa-font-path: "../../../node_modules/font-awesome/fonts";
@import '~font-awesome/scss/font-awesome';

// Import bootstrap, anything before this gets overridden by _reboot
@import '../node_modules/bootstrap/scss/bootstrap';
// Overrides go here

body, .mat-card {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #000000;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;

  .mat-dialog-content {
    max-height: 100%;
  }
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.customize-menu.mat-menu-panel{
  min-width: 0;
}


a {
  color: black;

  &:hover {
    color: black;
  }
}

p{
  margin: 0 0 10px;
}

.share-actions {
  .btn {
    padding: 2px 12px;
    font-size: 16px;
    color: white;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.75) center center no-repeat;
    background-size: auto 70%;
  }

  .btn:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
  }

  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn + .btn {
    margin-left: 0;
  }
}


/* Add QR Code custom styles here */
.center {
  display: flex;
  flex: 1;
  justify-content: center;
}

::ng-deep .wf-loading, .wf-materialicons-n4-inactive {
  .material-icons {
    display: none
  }
}
